import axios from 'axios';
import qs from 'qs';

//获取 员工信息列表
export const getEmployeeList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/personnel/employee/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 员工信息列表
export const deleteEmployeeList = (params, config) => {
    return axios.delete(`/personnel/employee/delete/${params}`, config).then(res => res)
}
//新增 员工信息列表
export const addEmployeeList = (params, config) => {
    return axios.post(`/personnel/employee/add`, qs.stringify(params), config).then(res => res)
}
//获取 员工信息列表详情
export const getInfoDetail = (params, config) => {
    return axios.get(`/personnel/employee/info/${params}`, config)
}
//修改 员工信息列表
export const updateDetail = (params, config) => {
    return axios.put(`/personnel/employee/update`, qs.stringify(params), config).then(res => res)
}


//获取 年度培训计划列表
export const getAnnualList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/personnel/annual/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 年度培训计划列表
export const deleteAnnualList = (params, config) => {
    return axios.delete(`/personnel/annual/delete/${params}`, config).then(res => res)
}
//批准 年度培训计划列表
export const approveAnnualList = (params, config) => {
    return axios.put(`/personnel/annual/approve`, qs.stringify(params), config).then(res => res)
}
//审核 年度培训计划列表
export const reviewAnnualList = (params, config) => {
    return axios.put(`/personnel/annual/review`, qs.stringify(params), config).then(res => res)
}
//新增 年度培训计划列表
export const addAnnualList = (params, config) => {
    return axios.post(`/personnel/annual/add`, qs.stringify(params), config).then(res => res)
}
//新增 年度培训计划列表详情
export const addAnnualInfoDetail = (params, config) => {
    return axios.post(`/personnel/annual/addDetail`, qs.stringify(params), config).then(res => res)
}
//获取 年度培训计划列表
export const getAnnualDetail = (params, config) => {
    return axios.get(`/personnel/annual/info/${params}`, config)
}
//修改 年度培训计划列表
export const updateAnnualDetail = (params, config) => {
    return axios.put(`/personnel/annual/update`, qs.stringify(params), config).then(res => res)
}



//获取 受控文件清单列表
export const getControlledList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/personnel/controlled/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 受控文件清单列表
export const deleteControlledList = (params, config) => {
    return axios.delete(`/personnel/controlled/delete/${params}`, config).then(res => res)
}
//新增 受控文件清单列表
export const addControlledList = (params, config) => {
    return axios.post(`/personnel/controlled/add`, qs.stringify(params), config).then(res => res)
}
//获取 受控文件清单列表
export const getControlledDetail = (params, config) => {
    return axios.get(`/personnel/controlled/info/${params}`, config)
}
//修改 受控文件清单列表
export const updateControlledDetail = (params, config) => {
    return axios.put(`/personnel/controlled/update`, qs.stringify(params), config).then(res => res)
}


//获取 外来文件清单列表
export const getExternalList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/personnel/external/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 外来文件清单列表
export const deleteExternalList = (params, config) => {
    return axios.delete(`/personnel/external/delete/${params}`, config).then(res => res)
}
//新增 外来文件清单列表
export const addExternalList = (params, config) => {
    return axios.post(`/personnel/external/add`, qs.stringify(params), config).then(res => res)
}
//获取 外来文件清单列表
export const getExternalDetail = (params, config) => {
    return axios.get(`/personnel/external/info/${params}`, config)
}
//修改 外来文件清单列表
export const updateExternalDetail = (params, config) => {
    return axios.put(`/personnel/external/update`, qs.stringify(params), config).then(res => res)
}



//获取 培训/会议信息列表
export const getMeetingList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/personnel/meeting/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//获取 签到列表
export const getSignMeetingList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/personnel/meeting/signList`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 培训/会议信息列表
export const deleteMeetingList = (params, config) => {
    return axios.delete(`/personnel/meeting/delete/${params}`, config).then(res => res)
}
//记录 培训/会议信息列表
export const recordingMeetingList = (params, config) => {
    return axios.post(`/personnel/meeting/recording`, qs.stringify(params), config).then(res => res)
}
//签到 培训/会议信息列表
export const signMeetingList = (params, config) => {
    return axios.post(`/personnel/meeting/sign`, qs.stringify(params), config).then(res => res)
}
//新增 培训/会议信息列表
export const addMeetingList = (params, config) => {
    return axios.post(`/personnel/meeting/add`, qs.stringify(params), config).then(res => res)
}
//获取 培训/会议信息列表
export const getMeetingDetail = (params, config) => {
    return axios.get(`/personnel/meeting/info/${params}`, config)
}
//修改 培训/会议信息列表
export const updateMeetingDetail = (params, config) => {
    return axios.put(`/personnel/meeting/update`, qs.stringify(params), config).then(res => res)
}




//获取 文件归档登记表
export const getRegistrationList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/personnel/registration/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 文件归档登记表
export const deleteRegistrationList = (params, config) => {
    return axios.delete(`/personnel/registration/delete/${params}`, config).then(res => res)
}
//新增 文件归档登记表
export const addRegistrationList = (params, config) => {
    return axios.post(`/personnel/registration/add`, qs.stringify(params), config).then(res => res)
}
//获取 文件归档登记表
export const getRegistrationDetail = (params, config) => {
    return axios.get(`/personnel/registration/info/${params}`, config)
}
//修改 文件归档登记表
export const updateRegistrationDetail = (params, config) => {
    return axios.put(`/personnel/registration/update`, qs.stringify(params), config).then(res => res)
}



//获取 文件更改申请表
export const getChangeList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/personnel/change/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 文件更改申请表
export const deleteChangeList = (params, config) => {
    return axios.delete(`/personnel/change/delete/${params}`, config).then(res => res)
}
//新增 文件更改申请表
export const addChangeList = (params, config) => {
    return axios.post(`/personnel/change/add`, qs.stringify(params), config).then(res => res)
}
//获取 文件更改申请表
export const getChangeDetail = (params, config) => {
    return axios.get(`/personnel/change/info/${params}`, config)
}
//修改 文件更改申请表
export const updateChangeDetail = (params, config) => {
    return axios.put(`/personnel/change/update`, qs.stringify(params), config).then(res => res)
}


//获取 文件作废/销毁申请表
export const getDestroyList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/personnel/destroy/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 文件作废/销毁申请表
export const deleteDestroyList = (params, config) => {
    return axios.delete(`/personnel/destroy/delete/${params}`, config).then(res => res)
}
//新增 文件作废/销毁申请表
export const addDestroyList = (params, config) => {
    return axios.post(`/personnel/destroy/add`, qs.stringify(params), config).then(res => res)
}
//获取 文件作废/销毁申请表
export const getDestroyDetail = (params, config) => {
    return axios.get(`/personnel/destroy/info/${params}`, config)
}
//修改 文件作废/销毁申请表
export const updateDestroyDetail = (params, config) => {
    return axios.put(`/personnel/destroy/update`, qs.stringify(params), config).then(res => res)
}



//获取 员工培训记录
export const getTrainList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/personnel/train/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 员工培训记录
export const deleteTrainList = (params, config) => {
    return axios.delete(`/personnel/train/delete/${params}`, config).then(res => res)
}
//新增 员工培训记录
export const addTrainList = (params, config) => {
    return axios.post(`/personnel/train/add`, qs.stringify(params), config).then(res => res)
}
//获取 员工培训记录
export const getTrainDetail = (params, config) => {
    return axios.get(`/personnel/train/info/${params}`, config)
}
//修改 员工培训记录
export const updateTrainDetail = (params, config) => {
    return axios.put(`/personnel/train/update`, qs.stringify(params), config).then(res => res)
}



//获取 员工转正申请
export const getConversionList = (params, config) => {
    console.log(params, 'params')
    return axios.get(`/personnel/conversion/list`, {
        params: {
            ...params
        }
    }, config).then(res => res)
}
//删除 员工转正申请
export const deleteConversionList = (params, config) => {
    return axios.delete(`/personnel/conversion/delete/${params}`, config).then(res => res)
}
//新增 员工转正申请
export const addConversionList = (params, config) => {
    return axios.post(`/personnel/conversion/add`, qs.stringify(params), config).then(res => res)
}
//获取 员工转正申请
export const getConversionDetail = (params, config) => {
    return axios.get(`/personnel/conversion/info/${params}`, config)
}
//修改 员工转正申请
export const updateConversionDetail = (params, config) => {
    return axios.put(`/personnel/conversion/update`, qs.stringify(params), config).then(res => res)
}
//人事行政部审批 员工转正申请
export const personnelApproveConversion = (params, config) => {
    return axios.put(`/personnel/conversion/personnelApprove`, qs.stringify(params), config).then(res => res)
}
//部门领导审批 员工转正申请
export const deptApproveConversion = (params, config) => {
    return axios.put(`/personnel/conversion/deptApprove`, qs.stringify(params), config).then(res => res)
}
//总经理审批 员工转正申请
export const generalApproveConversion = (params, config) => {
    return axios.put(`/personnel/conversion/generalApprove`, qs.stringify(params), config).then(res => res)
}

